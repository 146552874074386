




































































import { Component, Vue } from 'vue-property-decorator';
import { dispatchUpdateServiceProviderById, dispatchGetServiceProviderById } from '@/store/devs/actions';

@Component
export default class ServiceProviderEdit extends Vue {
  public valid = true;
  public name: string = '';
  public institution: string = '';
  public actionCode: string = '';
  public is_active: boolean = false;
  public parameter: string = '';
  public description: string = '';
  public providerFee: number = 0;
  public hulupayFee: number = 0;
  public provider =  {};
  public providerId : number = 0;


public async mounted() {
    
    const id:number = parseInt(this.$router.currentRoute.params.id);
    this.providerId = id;

    const response = await dispatchGetServiceProviderById(this.$store, parseInt(this.$router.currentRoute.params.id));
    
    if (response) {
    
      this.name = response.name;
      this.institution = response.institution;
      this.actionCode = response.actioncode;
      this.is_active = response.is_active;
      this.parameter = response.parameter;
      this.description = response.description;
      this.providerFee = response.serviceproviderFee;
      this.hulupayFee = response.hulupayFee;
    }
  }




 

  public reset() {
      this.name = "";
      this.institution = "";
      this.actionCode = "";
      this.is_active = false;
      this.parameter = "";
      this.description = "";
      this.providerFee = 0;
      this.hulupayFee = 0;
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if ((this.$refs.form as any).validate()) {
   
        this.provider["id"] = this.providerId;
        this.provider["name"] = this.name;
        this.provider["institution"] = this.institution;
        this.provider["actioncode"] = this.actionCode;
        this.provider["is_active"] = this.is_active;
        this.provider["parameter"] = this.parameter;
        this.provider["description"] = this.description;
        this.provider["serviceproviderFee"] = this.providerFee;
        this.provider["hulupayFee"] = this.hulupayFee;
                      

      await dispatchUpdateServiceProviderById(this.$store, this.provider);
      this.$router.push('/main/admin/serviceProviders/all');
    }
  }
}
