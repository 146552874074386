
































import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import { dispatchreadServiceProviders } from '@/store/devs/actions';
import { dispatchGetUsers } from '@/store/admin/actions';

@Component
export default class AdminUsers extends Vue {
  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Institution',
      sortable: true,
      value: 'app_code',
      align: 'left',
    },
    {
      text: 'Active',
      sortable: true,
      value: 'is_active',
      align: 'left',
    },
    {
      text: 'Action Code',
      sortable: false,
      value: 'actioncode',
      align: 'left',
    },
    {
      text: 'Description',
      sortable: false,
      value: 'description',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
    },
  ];

  private listofproviders = [];

  public async mounted() {
       const response = await dispatchreadServiceProviders(this.$store);
    this.getproviders();
    console.log(response);
  }

  private async getproviders() {
    const response = await dispatchreadServiceProviders(this.$store);
    if (response && response?.length > 0) {
      this.listofproviders = response;
    //   console.log(response);
    //   {{ log(this.listofproviders) }}

    }
  }

}
