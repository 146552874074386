



































import { Component, Vue } from 'vue-property-decorator';
import { appName } from '@/env';
import { dispatchCreateServiceProvider } from '@/store/devs/actions';

@Component
export default class CreateApp extends Vue {
  public valid = false;
  public providerName: string = '';
  public description: string = '';
  public isActive: boolean = false;
  public institution: string = '';
  public actioncode: string = '';
  public parameter: string  = '';
  public serviceproviderFee: number = 0;
  public hulupayFee: number = 0;


  public async mounted() {
    // this.reset();
    // this.submit();
  }

  public reset() {
    // this.providerName = '';
    // this.description = '';
    // this.isActive = false;
    // this.description = false;
    // this.actioncode;
    // this.institution: string;
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    // if (await this.$validator.validateAll()) {
    // }
    const newApp = {
    
        name: this.providerName,
        is_active: this.isActive,
        description : this.description,
        institution: this.institution,
        actioncode: this.actioncode,
        parameter: this.parameter,
        serviceproviderFee: this.serviceproviderFee,
        hulupayFee: this.hulupayFee


    };
    await dispatchCreateServiceProvider(this.$store, newApp);
    this.$router.push('/main/admin/serviceProviders/all');
  }
}
