



























































import { Component, Vue, Watch} from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import { dispatchGetUsers } from '@/store/admin/actions';
import { 
    dispatchreadAppsForUser, 
    dispatchreadTransactionsForUser, 
    dispatchreadServiceProviders} from '@/store/devs/actions';


@Component
export default class Settings extends Vue {
  public headers = [
    {
      text: 'Sender',
    //   sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'AppCode',
      sortable: true,
      value: 'app_code',
      align: 'left',
    },
    {
      text: 'Service Provider',
      sortable: true,
      value: 'service_provider',
      align: 'left',
    },
    {
      text: 'Date',
      sortable: true,
      value: 'date',
      align: 'left',
    },
    {
      text: 'Amount',
      sortable: false,
      value: 'amount',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
    },
  ];

  public valid = true;
  private listoftransactions = [];
  private listofproviders = [];
  private providernames = [];
  private providers_id_objects = {};
  public accountNumber = "";
  public phoneNumber = "";


  // public async beforeMount() {
  //   this.getTransactions();
  //   this.getServiceProviders();
  //   this.build_providers_obj();

  // }

  // const bankRegex = /^(^1000)\d{9}$/;
  // const phoneRegex = /^(^0)9\d{8}$/;

  public async mounted() {
    // console.log(this.providers_id_objects)

    this.accountNumber = "100098765422344";
    this.phoneNumber = "0987654321";
  }


  public reset() {
      this.accountNumber = "";
      this.phoneNumber = "";
     
    //   this.created_at = "";
     
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if ((this.$refs.form as any).validate()) {
   
      //   this.appInfo["AppCode"] = this.AppCode;
      //   this.appInfo["name"] = this.name;
      //   this.appInfo["application_code"] = this.application_code;
      //   this.appInfo["description"] = this.description;
      //   this.appInfo["is_active"] = this.is_active;
      //   this.appInfo["is_live"] = this.is_live;
      //   // this.provider["created_at"] = this.created_at;
      //   // this.provider["serviceproviderFee"] = this.providerFee;
      //   // this.provider["hulupayFee"] = this.hulupayFee;
                      

      // await dispatchUpdateAppByCode(this.$store, this.appInfo);
      // this.$router.push('/main/devs/apps/all');
    }
  }
  
//   private selectProvider(id) {
//     this.listofproviders.forEach(provider => {
//         if (provider.id == id) {
//             // console.log(provider)
//             return provider            
//         }
//         else {
//             return "UNKNOWN"
//         }        
//     });
//   }


// public getProviderName(id) {
//     this.listofproviders.forEach(provider => {
//         if (provider['id'] == id){
//             console.log(provider['name'])
            
//             return provider['name'];

//         }
//         else {
//             return "UNKNOWN"
//         }
//     });
// }

// public build_providers_obj(){
//   this.listofproviders.forEach(provider => {
//         this.providers_id_objects[provider['id']] = provider['name'] 
//     });

// }

//   private async getTransactions() {
//     const response = await dispatchreadTransactionsForUser(this.$store);
//     if (response && response?.length > 0) {
//       this.listoftransactions = response;
//     }
//   }

//   private async getServiceProviders() {
//     const response = await dispatchreadServiceProviders(this.$store);
//     if (response && response?.length > 0) {
//       this.listofproviders = response;
//     }
//   }


}
